// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../pages/Layout';

const ProtectedRoute = ({ element: Element, ...rest }) => {
    const isAuthenticated = useSelector((state) => state?.user?.user);

    return isAuthenticated.token ?
        <Layout component={Element} />
        :
        <Navigate to="/login" />;
};

export default ProtectedRoute;
