import { Box, Button, FormControl, FormLabel, Grid, GridItem, HStack, Input, Select, Skeleton, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LiaSaveSolid } from 'react-icons/lia';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStatesByCountryId } from '../../redux/general/generalActions';
import { submitCardData } from '../../redux/card/cardActions';
import { TiArrowBackOutline } from 'react-icons/ti';

const AddCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state?.user?.user);
    const states = useSelector(state => state?.general?.states);
    const isStatesLoading = useSelector(state => state?.general?.get_states_loading);
    const [cardNumberError, setCardNumberError] = useState('');
    const [typeError, setTypeError] = useState('');
    const isSubmitting = useSelector(state => state?.card?.isCardSubmitLoading);

    useEffect(() => {
        getStates();
    }, []);

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            nameOnCard: '',
            type: '',
            phone: '',
            email: '',
            city: '',
            state: '',
            address: '',
            card_number: '',
            card_type: '',
            exp_month: '',
            exp_year: '',
            bank_name: '',
            account_number: ''
        }
    });

    const onSubmit = (values) => {
        setCardNumberError('');
        setTypeError('');
        const data = {
            user_id: user?.user?.id,
            ...values
        }
        const token = user?.token;
        dispatch(submitCardData(data, token, afterSuccess, onError));
    }

    const getStates = () => {
        const data = {
            country_id: 1
        };

        const token = user?.token;
        dispatch(getStatesByCountryId(data, token));
    }

    const afterSuccess = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top'
        });
        reset();
    }

    const onError = (errors) => {
        if (errors?.card_number) {
            setCardNumberError(errors?.card_number[0]);
        }

        if (errors?.type) {
            setTypeError(errors?.type[0]);
        }
    }

    const month = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i <= 10; i++) {
        years.push(currentYear + i);
    }

    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Add Card | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Skeleton isLoaded={!isStatesLoading}>
                    <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'20px'}>Add Card</Text>
                </Skeleton>
                <Skeleton isLoaded={!isStatesLoading}>
                    <Button
                        backgroundColor={'#2BC155'}
                        borderRadius={'50px'}
                        padding={'10px 10px 10px 10px'}
                        color={'#fff'}
                        border={'1px solid #2BC155'}
                        display="inline-flex"
                        alignItems="center"
                        className="group"
                        transition={'all 0.3s ease-in-out'}
                        _hover={{
                            backgroundColor: '#fff',
                            color: '#2BC155',
                            border: '1px solid #2BC155'
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <Box
                            bgColor={'#fff'}
                            color={'#2BC155'}
                            borderRadius={'50%'}
                            w={'25px'}
                            h={'25px'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            transition={'all 0.3s ease-in-out'}
                            mr={2}
                            _groupHover={{
                                bgColor: '#2BC155',
                                color: '#fff',
                            }}
                        >
                            <TiArrowBackOutline />
                        </Box>
                        back
                    </Button>
                </Skeleton>
            </HStack>
            <Box border={'1px solid #0A2B1B'} borderRadius={'24px'} m={'60px'} p={'40px'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                        <Text mb={'40px'} fontSize={'20px'} fontWeight={'600'} color={'#0A2B1B'}>Enter Basic Details: </Text>
                    </Skeleton>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem>
                            <FormControl isInvalid={errors?.nameOnCard?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Name on Card *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("nameOnCard", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.nameOnCard?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.nameOnCard?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.type?.message || typeError}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Type *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Select
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("type", {
                                            required: "This is required *"
                                        })}
                                    >
                                        <option value='' disabled={true}>Select Type</option>
                                        <option value='1'>Primary</option>
                                        <option value='0'>Secondary</option>
                                    </Select>
                                </Skeleton>
                                {errors?.type?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.type?.message}</Text>}
                                {typeError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{typeError}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.phone?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Telephone *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("phone", {
                                            required: 'This is required *',
                                            minLength: { value: 10, message: 'Minimum length should be 10' },
                                            maxLength: { value: 12, message: 'Maximum length should be 12' }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.phone?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.phone?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.email?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Email *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("email", {
                                            required: "This is required *",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Invalid email format"
                                            }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.email?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.email?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.city?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>City *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("city", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.city?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.city?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.state?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>State *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Select
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("state", {
                                            required: "This is required *"
                                        })}
                                    >
                                        <option value='' disabled={true}>Select State</option>
                                        {states?.length > 0 ? states?.map((state, i) => {
                                            return (
                                                <option value={state?.id} key={i}>{state?.name}</option>
                                            )
                                        }) : null}
                                    </Select>
                                </Skeleton>
                                {errors?.state?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.state?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors?.address?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Address *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("address", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.address?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.address?.message}</Text>}
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                        <Text m={'40px 0px'} fontSize={'20px'} fontWeight={'600'} color={'#0A2B1B'}>Enter Card Details: </Text>
                    </Skeleton>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem>
                            <FormControl isInvalid={errors?.card_number?.message || cardNumberError}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Card Number *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("card_number", {
                                            required: "This is required *",
                                            minLength: { value: 13, message: 'Minimum length should be 13' },
                                            maxLength: { value: 19, message: 'Maximum length should be 19' }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.card_number?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.card_number?.message}</Text>}
                                {cardNumberError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{cardNumberError}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.card_type?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Card Type *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Select
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("card_type", {
                                            required: "This is required *"
                                        })}
                                    >
                                        <option value='' disabled={true}>Select Card Type</option>
                                        <option value='VISA'>Visa</option>
                                        <option value='MASTERCARD'>Mastercard</option>
                                        <option value='AMEX'>American Express (Amex)</option>
                                        <option value='DISCOVER'>Discover</option>
                                    </Select>
                                </Skeleton>
                                {errors?.card_type?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.card_type?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.exp_month?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Expiry Month *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Select
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("exp_month", {
                                            required: "This is required *"
                                        })}
                                    >
                                        <option value='' disabled={true}>Select Expiry Month</option>
                                        {month.map((data, ind) => {
                                            return (
                                                <option key={ind} value={data?.value}>{data?.label}</option>
                                            )
                                        })}
                                    </Select>
                                </Skeleton>
                                {errors?.exp_month?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.exp_month?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.exp_year?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Expiry Year *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Select
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("exp_year", {
                                            required: "This is required *"
                                        })}
                                    >
                                        <option value='' disabled={true}>Select Expiry Year</option>
                                        {years.map((data, ind) => {
                                            return (
                                                <option key={ind} value={data}>{data}</option>
                                            )
                                        })}
                                    </Select>
                                </Skeleton>
                                {errors?.exp_year?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.exp_year?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.bank_name?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Bank Name *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("bank_name", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.bank_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.bank_name?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.account_number?.message}>
                                <Skeleton isLoaded={!isStatesLoading} w={'max-content'}>
                                    <FormLabel>Account Number *</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isStatesLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("account_number", {
                                            required: "This is required *",
                                            minLength: { value: 8, message: 'Minimum length should be 8' },
                                            maxLength: { value: 17, message: 'Maximum length should be 17' }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.account_number?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.account_number?.message}</Text>}
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'50px'}>
                        <Skeleton isLoaded={!isStatesLoading}>
                            <Button
                                type='submit'
                                isLoading={isSubmitting}
                                backgroundColor={'#0A2B1B'}
                                borderRadius={'50px'}
                                padding={'10px 10px 10px 10px'}
                                color={'#fff'}
                                border={'1px solid #0A2B1B'}
                                display="inline-flex"
                                alignItems="center"
                                className="group"
                                transition={'all 0.3s ease-in-out'}
                                _hover={{
                                    backgroundColor: '#fff',
                                    color: '#0A2B1B',
                                    border: '1px solid #0A2B1B'
                                }}
                            >
                                <Box
                                    bgColor={'#fff'}
                                    color={'#0A2B1B'}
                                    borderRadius={'50%'}
                                    w={'25px'}
                                    h={'25px'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    transition={'all 0.3s ease-in-out'}
                                    mr={2}
                                    _groupHover={{
                                        bgColor: '#0A2B1B',
                                        color: '#fff',
                                    }}
                                >
                                    <LiaSaveSolid />
                                </Box>
                                Save
                            </Button>
                        </Skeleton>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}

export default AddCard;
