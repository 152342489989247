// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import generalReducer from './general/generalReducer';
import cardReducer from './card/cardReducer';
import moneySenderReducer from './moneySender/moneySenderReducer';

const rootReducer = combineReducers({
    user: userReducer,
    general: generalReducer,
    card: cardReducer,
    moneySender: moneySenderReducer
});

export default rootReducer;
