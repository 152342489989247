import React from 'react'
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Image,
} from '@chakra-ui/react'
import {
    FiSettings,
    FiMenu
} from 'react-icons/fi'
import Logo from '../assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { TbLayoutDashboard } from 'react-icons/tb';
import { LuRefreshCw, LuWallet } from 'react-icons/lu';
import { IoReceiptOutline } from 'react-icons/io5';
import { BsCreditCard2Back } from 'react-icons/bs';
import { FaRegBell, FaUsers } from 'react-icons/fa';
import { MdOutlineChat } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '../redux/user/userActions';
import { Helmet } from 'react-helmet';

const LinkItems = [
    { name: 'Dashboard', icon: TbLayoutDashboard, url: '/dashboard' },
    // { name: 'My Wallet', icon: LuWallet, url: '/my-wallet' },
    { name: 'Money Transfer', icon: LuRefreshCw, url: '/money-transfer' },
    { name: 'Money Sender', icon: IoReceiptOutline, url: '/money-sender' },
    { name: 'Cards Center', icon: BsCreditCard2Back, url: '/cards-center' },
    { name: 'Settings', icon: FiSettings, url: '/settings' },
]

const AdminLinkItems = [
    { name: 'Dashboard', icon: TbLayoutDashboard, url: '/dashboard' },
    { name: 'Transfer List', icon: LuWallet, url: '/transfer-list' },
    { name: 'Bank Transfer', icon: LuRefreshCw, url: '/bank-transfer-list' },
    { name: 'Client Details', icon: FaUsers, url: '/clients' },
    { name: 'Cards Center', icon: BsCreditCard2Back, url: '/cards-center' },
    { name: 'Settings', icon: FiSettings, url: '/settings' },
]

const SidebarContent = ({ location, onClose, currentYear, ...rest }) => {
    const isAdmin = useSelector(state => state?.user?.user?.user?.is_admin);

    return (
        <Box
            transition="3s ease"
            bg={'#F9F9F9'}
            borderRight="1px"
            borderRightColor={'#F9F9F9'}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Image src={Logo} />
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} textAlign={'center'} h={'88vh'}>
                <Box>
                    {isAdmin ?
                        AdminLinkItems.map((link) => (
                            <NavItem key={link.name} icon={link.icon} link={link.url} location={location}>
                                {link.name}
                            </NavItem>
                        ))
                        :
                        LinkItems.map((link) => (
                            <NavItem key={link.name} icon={link.icon} link={link.url} location={location}>
                                {link.name}
                            </NavItem>
                        ))}
                </Box>
                <Box>
                    <Text color={'#969BA0'} as={'b'} fontSize={'12px'}>Remitless Money Transfer</Text>
                    <Text color={'#969BA0'} fontSize={'12px'}>&copy; {currentYear} All Rights Reseved</Text>
                </Box>
            </Box>
        </Box>
    )
}

const NavItem = ({ icon, children, link, location, ...rest }) => {
    const fullPath = location.pathname;
    const extractedPath = fullPath.split('/').slice(0, 2).join('/');
    return (
        <Link
            to={link}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color={extractedPath === link ? '#000' : '#BFBFBF'}
                transition={'all 0.3s ease-in-out'}
                _hover={{
                    color: '#000'
                }}
                {...rest}>
                {icon && (
                    <Box
                        mr="3"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        h={'40px'}
                        w={'40px'}
                        bgColor={extractedPath === link ? '#2BB673' : ''}
                        borderRadius={extractedPath === link ? '50%' : '50%'}
                        transition={'all 0.3s ease-in-out'}
                        _groupHover={{
                            bgColor: '#2BB673',
                        }}
                    >
                        <Icon
                            fontSize="20"
                            color={extractedPath === link ? '#fff' : '#C4C4C4'}
                            transition={'all 0.3s ease-in-out'}
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    </Box>
                )}
                {children}
            </Flex>
        </Link>
    )
}

const MobileNav = ({ onOpen, onSignOut, user, ...rest }) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={'#F9F9F9'}
            borderBottomWidth="1px"
            borderBottomColor={'#F9F9F9'}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Image src={Logo} display={{ base: 'flex', md: 'none' }} />
            <HStack spacing={{ base: '2', md: '6' }}>
                <Box
                    bgColor={'#fff'}
                    w={'40px'}
                    h={'40px'}
                    borderRadius={'50%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    cursor={'pointer'}
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        bgColor: '#2BB673',
                        color: '#fff'
                    }}
                >
                    <FaRegBell />
                </Box>
                <Box
                    bgColor={'#fff'}
                    w={'40px'}
                    h={'40px'}
                    borderRadius={'50%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    cursor={'pointer'}
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        bgColor: '#2BB673',
                        color: '#fff'
                    }}
                >
                    <MdOutlineChat />
                </Box>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems={"center"}
                                    spacing="1px"
                                    mr="2">
                                    <Text fontSize="md" color={'#000'}>Hello, <Text as='b'>{user?.user?.first_name}</Text></Text>
                                    {/* <Text fontSize="xs" color="gray.600">
                                        Admin
                                    </Text> */}
                                </VStack>
                                <Avatar
                                    size={'md'}
                                    src={
                                        'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                />
                                {/* <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box> */}
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem>Profile</MenuItem>
                            <MenuItem>Settings</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={onSignOut}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    )
}

const Layout = ({ component }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = useSelector((state) => state?.user?.user);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const onSignOut = () => {
        dispatch(signOut(afterSuccess));
    }

    const afterSuccess = () => {
        navigate('/login');
    }

    return (
        <Box minH="100vh" bg={'#fff'}>
            <Helmet>
                <title>Dashboard | Remitless</title>
            </Helmet>
            <SidebarContent location={location} onClose={() => onClose} currentYear={currentYear} display={{ base: 'none', md: 'block' }} />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent location={location} onClose={onClose} currentYear={currentYear} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} user={user} onSignOut={onSignOut} />
            <Box ml={{ base: 0, md: 60 }} p="4" h={'89vh'} overflow={'auto'}>
                {component}
            </Box>
        </Box>
    )
}

export default Layout;