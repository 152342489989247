export const IS_MONEY_SENDER_SUBMIT_LOADING = "IS_MONEY_SENDER_SUBMIT_LOADING";
export const IS_MONEY_SENDER_SUBMIT_SUCCESS = "IS_MONEY_SENDER_SUBMIT_SUCCESS";
export const IS_MONEY_SENDER_SUBMIT_FAILED = "IS_MONEY_SENDER_SUBMIT_FAILED";
export const IS_GET_MONEY_SENDER_LOADING = "IS_GET_MONEY_SENDER_LOADING";
export const IS_GET_MONEY_SENDER_SUCCESS = "IS_GET_MONEY_SENDER_SUCCESS";
export const IS_GET_MONEY_SENDER_FAILED = "IS_GET_MONEY_SENDER_FAILED";
export const IS_GET_MONEY_SENDER_BY_ID_LOADING = "IS_GET_MONEY_SENDER_BY_ID_LOADING";
export const IS_GET_MONEY_SENDER_BY_ID_SUCCESS = "IS_GET_MONEY_SENDER_BY_ID_SUCCESS";
export const IS_GET_MONEY_SENDER_BY_ID_FAILED = "IS_GET_MONEY_SENDER_BY_ID_FAILED";
export const IS_MONEY_SENDER_UPDATE_LOADING = "IS_MONEY_SENDER_UPDATE_LOADING";
export const IS_MONEY_SENDER_UPDATE_SUCCESS = "IS_MONEY_SENDER_UPDATE_SUCCESS";
export const IS_MONEY_SENDER_UPDATE_FAILED = "IS_MONEY_SENDER_UPDATE_FAILED";
export const IS_MONEY_SENDER_DELETE_LOADING = "IS_MONEY_SENDER_DELETE_LOADING";
export const IS_MONEY_SENDER_DELETE_SUCCESS = "IS_MONEY_SENDER_DELETE_SUCCESS";
export const IS_MONEY_SENDER_DELETE_FAILED = "IS_MONEY_SENDER_DELETE_FAILED";