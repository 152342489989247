export const IS_CARD_SUBMIT_LOADING = "IS_CARD_SUBMIT_LOADING";
export const IS_CARD_SUBMIT_SUCCESS = "IS_CARD_SUBMIT_SUCCESS";
export const IS_CARD_SUBMIT_FAILED = "IS_CARD_SUBMIT_FAILED";
export const IS_GET_CARD_LOADING = "IS_GET_CARD_LOADING";
export const IS_GET_CARD_SUCCESS = "IS_GET_CARD_SUCCESS";
export const IS_GET_CARD_FAILED = "IS_GET_CARD_FAILED";
export const IS_GET_CARD_BY_ID_LOADING = "IS_GET_CARD_BY_ID_LOADING";
export const IS_GET_CARD_BY_ID_SUCCESS = "IS_GET_CARD_BY_ID_SUCCESS";
export const IS_GET_CARD_BY_ID_FAILED = "IS_GET_CARD_BY_ID_FAILED";
export const IS_CARD_UPDATING_LOADING = "IS_CARD_UPDATING_LOADING";
export const IS_CARD_UPDATING_SUCCESS = "IS_CARD_UPDATING_SUCCESS";
export const IS_CARD_UPDATING_FAILED = "IS_CARD_UPDATING_FAILED";
export const IS_CARD_DELETE_LOADING = "IS_CARD_DELETE_LOADING";
export const IS_CARD_DELETE_SUCCESS = "IS_CARD_DELETE_SUCCESS";
export const IS_CARD_DELETE_FAILED = "IS_CARD_DELETE_FAILED";