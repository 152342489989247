import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Authentication from './pages/auth/Authentication';
import GuestRoute from './routes/GuestRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import SenderList from './pages/moneySender/SenderList';
import AddMoneySender from './pages/moneySender/AddMoneySender';
import CardsCenter from './pages/cardsCenter/CardsCenter';
import MoneyTransfer from './pages/moneyTransfer/MoneyTransfer';
import TransferMoney from './pages/moneyTransfer/TransferMoney';
import AddCard from './pages/cardsCenter/AddCard';
import EditCard from './pages/cardsCenter/EditCard';
import EditMoneySender from './pages/moneySender/EditMoneySender';
import { useSelector } from 'react-redux';
import Dashboard from './pages/dashboards/Dashboard';
import AdminDashboard from './pages/dashboards/AdminDashboard';
import AdminMoneyTransferList from './pages/moneyTransfer/AdminMoneyTransferList';
import AdminBankTransferList from './pages/bankTransfer/AdminBankTransferList';
import ClientsList from './pages/clients/ClientsList';
import ClientsEdit from './pages/clients/ClientsEdit';

function App() {

  const isAdmin = useSelector((state) => state?.user?.user?.user?.is_admin);

  return (
    <Routes>
      <Route path="*" element={<ProtectedRoute element={<Navigate to="/dashboard" />} />} />
      <Route path="/login" element={<GuestRoute element={<Login />} />} />
      <Route path="/sign-up" element={<GuestRoute element={<Signup />} />} />
      <Route path="/forgot-password" element={<GuestRoute element={<ForgetPassword />} />} />
      <Route path="/reset-password" element={<GuestRoute element={<ResetPassword />} />} />
      <Route path="/authentication" element={<GuestRoute element={<Authentication />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={isAdmin ? <AdminDashboard /> : <Dashboard />} />} />
      <Route path="/money-sender" element={<ProtectedRoute element={<SenderList />} />} />
      <Route path="/money-sender/add" element={<ProtectedRoute element={<AddMoneySender />} />} />
      <Route path="/money-sender/edit/:id" element={<ProtectedRoute element={<EditMoneySender />} />} />
      <Route path="/cards-center" element={<ProtectedRoute element={<CardsCenter />} />} />
      <Route path="/cards-center/add-new-card" element={<ProtectedRoute element={<AddCard />} />} />
      <Route path="/cards-center/edit-card/:id" element={<ProtectedRoute element={<EditCard />} />} />
      <Route path="/money-transfer" element={<ProtectedRoute element={<MoneyTransfer />} />} />
      <Route path="/money-transfer/transfer" element={<ProtectedRoute element={<TransferMoney />} />} />
      <Route path="/transfer-list" element={<ProtectedRoute element={<AdminMoneyTransferList />} />} />
      <Route path="/bank-transfer-list" element={<ProtectedRoute element={<AdminBankTransferList />} />} />
      <Route path="/clients" element={<ProtectedRoute element={<ClientsList />} />} />
      <Route path="/clients/edit" element={<ProtectedRoute element={<ClientsEdit />} />} />
    </Routes>
  );
}

export default App;
