import { Post } from '../../helpers/apicalls/apicalls';
import * as actionTypes from './moneySenderTypes';

export const storeMoneySender = (data, token, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_MONEY_SENDER_SUBMIT_LOADING });
        Post('/api/store-money-sender', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.IS_MONEY_SENDER_SUBMIT_SUCCESS });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_MONEY_SENDER_SUBMIT_FAILED });
                afterError(err?.response?.data?.errors);
            });
    }
}

export const getMoneySendersList = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_GET_MONEY_SENDER_LOADING });
        Post('/api/get-money-senders', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_GET_MONEY_SENDER_SUCCESS,
                        payload: res?.data
                    });
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_GET_MONEY_SENDER_FAILED });
            });
    }
}

export const getMoneySenderDetailsById = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_GET_MONEY_SENDER_BY_ID_LOADING });
        Post('/api/get-money-sender-by-id', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_GET_MONEY_SENDER_BY_ID_SUCCESS,
                        payload: res?.data
                    });
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_GET_MONEY_SENDER_BY_ID_FAILED });
            })
    }
}

export const updateMoneySender = (data, token, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_MONEY_SENDER_UPDATE_LOADING });
        Post('/api/update-money-sender', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_MONEY_SENDER_UPDATE_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_MONEY_SENDER_UPDATE_FAILED });
                afterError(err?.response?.data?.errors);
            });
    }
}

export const deleteMoneySender = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_MONEY_SENDER_DELETE_LOADING });
        Post('/api/delete-money-sender', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.IS_MONEY_SENDER_DELETE_SUCCESS });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_MONEY_SENDER_DELETE_FAILED });
            })
    }
}