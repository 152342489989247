import * as actionTypes from './generalTypes';

const initialState = {
    states: [],
    get_states_loading: false
};

const generalReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.GET_STATES_LOADING:
            return {
                ...state,
                get_states_loading: true
            };
        case actionTypes.GET_STATES_SUCCESS:
            return {
                ...state,
                states: payload,
                get_states_loading: false
            };
        case actionTypes.GET_STATES_FAILED:
            return {
                ...state,
                get_states_loading: false
            };
        default:
            return state;
    }
};

export default generalReducer;