import { Get, Post } from '../../helpers/apicalls/apicalls';
import * as actionTypes from './cardTypes';

export const submitCardData = (data, token, afterSuccess, onError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_CARD_SUBMIT_LOADING });
        Post('/api/submit-card-data', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.IS_CARD_SUBMIT_SUCCESS });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.IS_CARD_SUBMIT_FAILED });
                onError(err?.response?.data?.errors);
            });
    }
}

export const getCards = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_GET_CARD_LOADING });
        Get('/api/get-cards', token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_GET_CARD_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.IS_GET_CARD_FAILED });
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_GET_CARD_FAILED });
            })
    }
}

export const getCardById = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_GET_CARD_BY_ID_LOADING });
        Post('/api/get-card-by-id', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_GET_CARD_BY_ID_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.IS_GET_CARD_BY_ID_FAILED });
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_GET_CARD_BY_ID_FAILED });
            });
    }
}

export const updateCardData = (data, token, afterSuccess, onError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_CARD_UPDATING_LOADING });
        Post('/api/update-card-data', data, token)
            .then((res) => {
                console.log(res, "res");
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_CARD_UPDATING_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_CARD_UPDATING_FAILED });
                onError(err?.response?.data?.errors);
            })
    }
}

export const deleteCard = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_CARD_DELETE_LOADING });
        Post('/api/delete-card', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.IS_CARD_DELETE_SUCCESS });
                    afterSuccess(res?.message);
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_CARD_DELETE_FAILED });
            })
    }
}