import * as actionTypes from './moneySenderTypes';

const initialState = {
    isMoneySenderSubmitLoading: false,
    isGetMoneySenderLoading: false,
    isGetMoneySenderByIdLoading: false,
    isMoneySenderUpdateLoading: false,
    isMoneySenderDeleteLoading: false,
    moneySenders: [],
    moneySenderForEdit: {}
};

const moneySenderReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_MONEY_SENDER_SUBMIT_LOADING:
            return {
                ...state,
                isMoneySenderSubmitLoading: true
            };
        case actionTypes.IS_MONEY_SENDER_SUBMIT_SUCCESS:
            return {
                ...state,
                isMoneySenderSubmitLoading: false
            };
        case actionTypes.IS_MONEY_SENDER_SUBMIT_FAILED:
            return {
                ...state,
                isMoneySenderSubmitLoading: false
            };
        case actionTypes.IS_GET_MONEY_SENDER_LOADING:
            return {
                ...state,
                isGetMoneySenderLoading: true
            };
        case actionTypes.IS_GET_MONEY_SENDER_SUCCESS:
            return {
                ...state,
                isGetMoneySenderLoading: false,
                moneySenders: payload
            };
        case actionTypes.IS_GET_MONEY_SENDER_FAILED:
            return {
                ...state,
                isGetMoneySenderLoading: false
            };
        case actionTypes.IS_GET_MONEY_SENDER_BY_ID_LOADING:
            return {
                ...state,
                isGetMoneySenderByIdLoading: true
            };
        case actionTypes.IS_GET_MONEY_SENDER_BY_ID_SUCCESS:
            return {
                ...state,
                isGetMoneySenderByIdLoading: false,
                moneySenderForEdit: payload
            };
        case actionTypes.IS_GET_MONEY_SENDER_BY_ID_FAILED:
            return {
                ...state,
                isGetMoneySenderByIdLoading: false
            };
        case actionTypes.IS_MONEY_SENDER_UPDATE_LOADING:
            return {
                ...state,
                isMoneySenderUpdateLoading: true
            };
        case actionTypes.IS_MONEY_SENDER_UPDATE_SUCCESS:
            return {
                ...state,
                isMoneySenderUpdateLoading: false,
                moneySenderForEdit: payload
            };
        case actionTypes.IS_MONEY_SENDER_UPDATE_FAILED:
            return {
                ...state,
                isMoneySenderUpdateLoading: false
            };
        case actionTypes.IS_MONEY_SENDER_DELETE_LOADING:
            return {
                ...state,
                isMoneySenderDeleteLoading: true
            };
        case actionTypes.IS_MONEY_SENDER_DELETE_SUCCESS:
            return {
                ...state,
                isMoneySenderDeleteLoading: false,
                moneySenderForEdit: payload
            };
        case actionTypes.IS_MONEY_SENDER_DELETE_FAILED:
            return {
                ...state,
                isMoneySenderDeleteLoading: false
            };
        default:
            return state;
    }
};

export default moneySenderReducer;