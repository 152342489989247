import { Box, Button, HStack, Image, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/images/no-image.png';
import { FaEllipsisV } from 'react-icons/fa';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { BsCreditCard2Back } from 'react-icons/bs';
import { AiOutlineBank } from 'react-icons/ai';
import { LuPhone } from 'react-icons/lu';
import { AddIcon } from '@chakra-ui/icons';

const ClientsList = () => {
    const navigate = useNavigate();
    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Clients | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'18px'}>Client List</Text>
                <Button
                    backgroundColor={'#2BC155'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #2BC155'}
                    display="inline-flex"
                    alignItems="center"
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#2BC155',
                        border: '1px solid #2BC155'
                    }}
                    onClick={() => navigate(-1)}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#2BC155'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#2BC155',
                            color: '#fff',
                        }}
                    >
                        <BiFilter />
                    </Box>
                    Advance Search
                </Button>
            </HStack>
            <Box mt={'30px'}>
                <SimpleGrid columns={3} spacing={8}>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem onClick={() => navigate("/clients/edit")} transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default ClientsList;
