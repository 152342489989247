import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
    return (
        <Box>
            <Helmet>
                <title>Dashboard | Remitless</title>
            </Helmet>
            <Text>Dashboard</Text>
        </Box>
    );
}

export default Dashboard;
