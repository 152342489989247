import { AddIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, HStack, Img, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BsCreditCard2Back } from 'react-icons/bs';
import { FaCaretDown, FaEllipsisV } from 'react-icons/fa';
import { TbArrowDownFromArc, TbArrowDownToArc } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/images/no-image.png';

const MoneyTransfer = () => {
    const navigate = useNavigate();

    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Money Transfer | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'18px'}>Money Transfer</Text>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaCaretDown />} color={'#000000'} border={'1px solid #000'} borderRadius={'30px'} bgColor={'#fff'}>
                        Select Card
                    </MenuButton>
                    <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Card 1</MenuItem>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Card 2</MenuItem>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Card 3</MenuItem>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Card 4</MenuItem>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Card 5</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <HStack alignItems={'center'} justifyContent={'space-between'} mt={'20px'}>
                <HStack alignItems={'flex-start'}>
                    <BsCreditCard2Back color={'#2BB673'} fontSize={'60px'} style={{ margin: '20px 10px 0px 0px' }} />
                    <Box>
                        <Text color={'#000000'} fontSize={'20px'} mb={'5px'} fontWeight={'500'}>card 1</Text>
                        <Text color={'#000000'} fontSize={'36px'} mb={'5px'} fontWeight={'600'}>$673,412.66</Text>
                        <Text color={'#969BA0'} fontSize={'20px'} fontWeight={'500'}>primary card</Text>
                    </Box>
                </HStack>
                <Box>
                    <Text fontSize={'14px'} color={'#00000052'} mb={'5px'} fontWeight={'500'}>valid thru</Text>
                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'500'}>08/21</Text>
                </Box>
                <Box>
                    <Text fontSize={'14px'} color={'#00000052'} mb={'5px'} fontWeight={'500'}>card holder</Text>
                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'500'}>Garcia Jr.</Text>
                </Box>
                <Text fontSize={'20px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                <Menu>
                    <MenuButton>
                        <FaEllipsisV fontSize={'25px'} />
                    </MenuButton>
                    <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                        <MenuItem transition={'all 0.2s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <Box
                display={'flex'}
                justifyContent={'flex-end'}
                position={'relative'}
                _before={{
                    content: '""',
                    width: '75%',
                    height: '8px',
                    backgroundColor: '#F1F1F1',
                    borderRadius: '30px',
                    position: 'absolute',
                    top: '16px',
                    left: '0px'
                }}
            >
                <Button
                    mr={'20px'}
                    backgroundColor={'#2BC155'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #2BC155'}
                    display={'inline-flex'}
                    alignItems={'center'}
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#2BC155',
                        border: '1px solid #2BC155'
                    }}
                    onClick={() => navigate('/money-sender/add')}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#2BC155'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#2BC155',
                            color: '#fff',
                        }}
                    >
                        <AddIcon fontSize={'13px'} />
                    </Box>
                    Add Money Sender
                </Button>
                <Button
                    backgroundColor={'#0A2B1B'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #0A2B1B'}
                    display={'inline-flex'}
                    alignItems={'center'}
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#0A2B1B',
                        border: '1px solid #0A2B1B'
                    }}
                    onClick={() => navigate('/money-transfer/transfer')}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#0A2B1B'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#0A2B1B',
                            color: '#fff',
                        }}
                    >
                        <TbArrowDownFromArc fontSize={'13px'} />
                    </Box>
                    Transfer Money
                </Button>
            </Box>
            <Box bgColor={'#d9d9d93b'} p={'20px'} borderRadius={'24px'} mt={'20px'}>
                <HStack justifyContent={'space-between'}>
                    <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'18px'}>My Recent Money Transfer</Text>
                </HStack>
                <TableContainer>
                    <Table variant='simple'>
                        <Tbody>
                            <Tr borderBottom={'2px solid #000000cc'} _last={{ border: 'none' }}>
                                <Td>
                                    <Box w={'65px'} h={'65px'} position={'relative'}>
                                        <Img src={noImage} />
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>Capital Bank</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                            </Tr>
                            <Tr borderBottom={'2px solid #000000cc'} _last={{ border: 'none' }}>
                                <Td>
                                    <Box w={'65px'} h={'65px'} position={'relative'}>
                                        <Img src={noImage} />
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#A93E3E'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Andrew Lee</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>Chase Bank</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$19,658</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>VISA Card 404</Text>
                                </Td>
                                <Td><Text bgColor={'#A93E3E'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Canceled</Text></Td>
                            </Tr>
                            <Tr borderBottom={'2px solid #000000cc'} _last={{ border: 'none' }}>
                                <Td>
                                    <Box w={'65px'} h={'65px'} position={'relative'}>
                                        <Img src={noImage} />
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#DE5B11'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>Capital Bank</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#DE5B11'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Pending</Text></Td>
                            </Tr>
                            <Tr borderBottom={'2px solid #000000cc'} _last={{ border: 'none' }}>
                                <Td>
                                    <Box w={'65px'} h={'65px'} position={'relative'}>
                                        <Img src={noImage} />
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>Capital Bank</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                            </Tr>
                            {/* <Tr>
                                <Td><Img src={card2} /></Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                </Td>
                                <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td><Img src={card3} /></Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                </Td>
                                <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td><Img src={card4} /></Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                </Td>
                                <Td>
                                    <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                </Td>
                                <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr> */}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default MoneyTransfer;
