import { Box, Button, FormControl, FormLabel, Grid, GridItem, HStack, Input, Skeleton, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { LiaSaveSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getMoneySenderDetailsById, storeMoneySender, updateMoneySender } from '../../redux/moneySender/moneySenderActions';
import { TiArrowBackOutline } from 'react-icons/ti';

const EditMoneySender = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const toast = useToast();
    const user = useSelector(state => state?.user?.user);
    const token = useSelector(state => state?.user?.user?.token);
    const isSubmitting = useSelector(state => state?.moneySender?.isMoneySenderUpdateLoading);
    const [accountNumberError, setAccountNumberError] = useState(null);
    const isGetMoneySenderByIdLoading = useSelector(state => state?.moneySender?.isGetMoneySenderByIdLoading);
    const moneySenderForEdit = useSelector(state => state?.moneySender?.moneySenderForEdit);

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {}
    });

    useEffect(() => {
        getMoneySenderById();
    }, []);

    useEffect(() => {
        setValue('first_name', moneySenderForEdit?.first_name);
        setValue('last_name', moneySenderForEdit?.last_name);
        setValue('phone', moneySenderForEdit?.phone);
        setValue('email', moneySenderForEdit?.email);
        setValue('address', moneySenderForEdit?.address);
        setValue('account_title', moneySenderForEdit?.account_title);
        setValue('account_number', moneySenderForEdit?.account_number);
        setValue('bank_name', moneySenderForEdit?.bank_name);
    }, [moneySenderForEdit]);

    const getMoneySenderById = () => {
        const data = {
            id: atob(id)
        };

        dispatch(getMoneySenderDetailsById(data, token));
    }

    const onSubmit = (values) => {
        setAccountNumberError(null);
        const data = {
            id: atob(id),
            user_id: user?.user?.id,
            ...values
        };

        dispatch(updateMoneySender(data, token, afterSuccess, afterError));
    }

    const afterSuccess = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top'
        });
    }

    const afterError = (errors) => {
        setAccountNumberError(errors?.account_number[0]);
    }

    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Edit Money Sender | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                    <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'20px'}>Edit Money Sender</Text>
                </Skeleton>
                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                    <Button
                        backgroundColor={'#2BC155'}
                        borderRadius={'50px'}
                        padding={'10px 10px 10px 10px'}
                        color={'#fff'}
                        border={'1px solid #2BC155'}
                        display="inline-flex"
                        alignItems="center"
                        className="group"
                        transition={'all 0.3s ease-in-out'}
                        _hover={{
                            backgroundColor: '#fff',
                            color: '#2BC155',
                            border: '1px solid #2BC155'
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <Box
                            bgColor={'#fff'}
                            color={'#2BC155'}
                            borderRadius={'50%'}
                            w={'25px'}
                            h={'25px'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            transition={'all 0.3s ease-in-out'}
                            mr={2}
                            _groupHover={{
                                bgColor: '#2BC155',
                                color: '#fff',
                            }}
                        >
                            <TiArrowBackOutline />
                        </Box>
                        back
                    </Button>
                </Skeleton>
            </HStack>
            <Box border={'1px solid #0A2B1B'} borderRadius={'24px'} m={'60px'} p={'40px'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem>
                            <FormControl isInvalid={errors?.first_name?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>First Name</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("first_name", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.first_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.first_name?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.last_name?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Last Name</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("last_name", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.last_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.last_name?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.phone?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Telephone</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("phone", {
                                            required: 'This is required *',
                                            minLength: { value: 10, message: 'Minimum length should be 10' },
                                            maxLength: { value: 12, message: 'Maximum length should be 12' }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.phone?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.phone?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.email?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Email</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("email", {
                                            required: "This is required *",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Invalid email format"
                                            }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.email?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.email?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors?.address?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Address</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("address", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.address?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.address?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.account_title?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Account Title</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("account_title", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.account_title?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.account_title?.message}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.account_number?.message || accountNumberError}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Account Number</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("account_number", {
                                            required: "This is required *",
                                            minLength: { value: 8, message: 'Minimum length should be 8' },
                                            maxLength: { value: 17, message: 'Maximum length should be 17' }
                                        })}
                                    />
                                </Skeleton>
                                {errors?.account_number?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.account_number?.message}</Text>}
                                {accountNumberError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{accountNumberError}</Text>}
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isInvalid={errors?.bank_name?.message}>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading} w={'max-content'}>
                                    <FormLabel>Bank Name</FormLabel>
                                </Skeleton>
                                <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                                    <Input
                                        bgColor={'#F8F7FC'}
                                        borderColor={'#F8F7FC'}
                                        _hover={{ borderColor: '#F8F7FC' }}
                                        color={'#9A999C'}
                                        {...register("bank_name", {
                                            required: "This is required *"
                                        })}
                                    />
                                </Skeleton>
                                {errors?.bank_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.bank_name?.message}</Text>}
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'50px'}>
                        <Skeleton isLoaded={!isGetMoneySenderByIdLoading}>
                            <Button
                                type='submit'
                                isLoading={isSubmitting}
                                backgroundColor={'#0A2B1B'}
                                borderRadius={'50px'}
                                padding={'10px 10px 10px 10px'}
                                color={'#fff'}
                                border={'1px solid #0A2B1B'}
                                display="inline-flex"
                                alignItems="center"
                                className="group"
                                transition={'all 0.3s ease-in-out'}
                                _hover={{
                                    backgroundColor: '#fff',
                                    color: '#0A2B1B',
                                    border: '1px solid #0A2B1B'
                                }}
                            >
                                <Box
                                    bgColor={'#fff'}
                                    color={'#0A2B1B'}
                                    borderRadius={'50%'}
                                    w={'25px'}
                                    h={'25px'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    transition={'all 0.3s ease-in-out'}
                                    mr={2}
                                    _groupHover={{
                                        bgColor: '#0A2B1B',
                                        color: '#fff',
                                    }}
                                >
                                    <LiaSaveSolid />
                                </Box>
                                Update Details
                            </Button>
                        </Skeleton>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}

export default EditMoneySender;
