import { Box, Button, Grid, GridItem, HStack, Img, Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import noImage from '../../assets/images/no-image.png';
import { TbArrowUpFromArc } from 'react-icons/tb';

const AdminDashboard = () => {

    const [accountStatusChartOptions, setAccountStatusChartOptions] = useState({
        chart: {
            id: "basic-bar",
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 10,
                borderRadiusApplication: 'end'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 4,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value + "K" },
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: (val) => { return "$ " + val + "k" }
            }
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            markers: {
                size: 7,
                shape: 'circle',
            }
        }
    });

    const [accountStatusChartSeries, setAccountStatusChartSeries] = useState([
        {
            name: "Transfer",
            data: [30, 40, 45, 50, 49, 60, 70, 91, 20, 30, 40, 45],
            color: '#2BC155'
        },
        {
            name: "Income",
            data: [20, 30, 35, 40, 39, 50, 60, 81, 10, 20, 30, 35],
            color: '#37ECBA'
        }
    ]);

    const [accountStatusFilterActive, setAccountStatusFilterActive] = useState({
        'weekly': false,
        'monthly': false,
        'yearly': true
    });

    const [bankTransferChartSeries, setBankTransferChartSeries] = useState([44, 55, 41, 17, 15]);
    const [bankTransferChartOptions, setBankTransferChartOptions] = useState({
        chart: {
            type: 'donut'
        },
        legend: {
            show: false,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Poppins, san-serif',
            fontWeight: 400,
            onItemClick: {
                toggleDataSeries: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#1EAAE7', '#6418C3', '#2BC155', '#FF7A30', '#FFEF5F'],
        labels: ['Bank of America', 'Capital One', 'Chase Bank', 'Wells Fargo', 'Goldman Sachs Bank']
    });

    const onAccountStatusFilterChange = (e, val) => {
        e.preventDefault();

        if (val === 'weekly') {
            setAccountStatusFilterActive({
                'weekly': true,
                'monthly': false,
                'yearly': false
            });
        }

        if (val === 'monthly') {
            setAccountStatusFilterActive({
                'weekly': false,
                'monthly': true,
                'yearly': false
            });
        }

        if (val === 'yearly') {
            setAccountStatusFilterActive({
                'weekly': false,
                'monthly': false,
                'yearly': true
            });
        }
    }

    return (
        <Box>
            <Helmet>
                <title>Dashboard | Remitless</title>
            </Helmet>
            <Grid
                templateRows='repeat(2, 1fr)'
                templateColumns='repeat(5, 1fr)'
                gap={4}
            >
                <GridItem rowSpan={1} colSpan={5}>
                    <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'} borderRadius={'24px'}>
                        <HStack justifyContent={'space-between'}>
                            <Text
                                fontSize={'18px'}
                                fontWeight={'600'}
                                color={'#000000'}
                            >Account Status</Text>
                            <Box>
                                <Button
                                    borderRadius={'24px'}
                                    bgColor={accountStatusFilterActive?.weekly ? '#0a2b1b' : 'transparent'}
                                    color={accountStatusFilterActive?.weekly ? '#fff' : '#0a2b1b'}
                                    fontSize={'14px'}
                                    _hover={{
                                        bgColor: '#2bb673',
                                        color: '#fff'
                                    }}
                                    onClick={(e) => onAccountStatusFilterChange(e, 'weekly')}
                                >
                                    Weekly
                                </Button>
                                <Button
                                    borderRadius={'24px'}
                                    bgColor={accountStatusFilterActive?.monthly ? '#0a2b1b' : 'transparent'}
                                    color={accountStatusFilterActive?.monthly ? '#fff' : '#0a2b1b'}
                                    fontSize={'14px'}
                                    ml={'5px'}
                                    _hover={{
                                        bgColor: '#2bb673',
                                        color: '#fff'
                                    }}
                                    onClick={(e) => onAccountStatusFilterChange(e, 'monthly')}
                                >
                                    Monthly
                                </Button>
                                <Button
                                    borderRadius={'24px'}
                                    bgColor={accountStatusFilterActive?.yearly ? '#0a2b1b' : 'transparent'}
                                    color={accountStatusFilterActive?.yearly ? '#fff' : '#0a2b1b'}
                                    fontSize={'14px'}
                                    ml={'5px'}
                                    _hover={{
                                        bgColor: '#2bb673',
                                        color: '#fff'
                                    }}
                                    onClick={(e) => onAccountStatusFilterChange(e, 'yearly')}
                                >
                                    Yearly
                                </Button>
                            </Box>
                        </HStack>
                        <Box mt={'40px'}>
                            <ReactApexChart
                                options={accountStatusChartOptions}
                                series={accountStatusChartSeries}
                                type="bar"
                                height="350px"
                            />
                        </Box>
                    </Box>
                </GridItem>
                <GridItem
                    colSpan={3}
                >
                    <Box
                        bgColor={'#d9d9d93b'}
                        borderRadius={'24px'}
                        boxShadow={'5px 5px 10px #00000017'}
                        padding={'20px'}
                        h={'493px'}
                    >
                        <HStack justifyContent={'space-between'}>
                            <Text
                                fontSize={'18px'}
                                fontWeight={'600'}
                                color={'#000000'}
                            >Transfer History</Text>
                            <Box>
                                <Link
                                    style={{
                                        color: '#0A2B1B',
                                        textDecoration: 'underline',
                                        display: 'inline-block',
                                        margin: '0px',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}
                                >
                                    View All
                                </Link>
                                <Button
                                    borderRadius={'24px'}
                                    bgColor={'#0a2b1b'}
                                    color={'#fff'}
                                    ml={'10px'}
                                    fontSize={'14px'}
                                    _hover={{
                                        bgColor: '#0a2b1b',
                                        color: '#fff'
                                    }}
                                >Today</Button>
                            </Box>
                        </HStack>
                        <TableContainer overflow={'hidden'}>
                            <Table variant='simple'>
                                <Tbody>
                                    <Tr>
                                        <Td border={'none'} pl={'0px'}>
                                            <Box
                                                w={'63px'}
                                                h={'63px'}
                                                borderRadius={'50%'}
                                                position={'relative'}
                                                bgColor={'#C4C4C4'}
                                            >
                                                <Box
                                                    w={'36px'}
                                                    h={'36px'}
                                                    borderRadius={'50%'}
                                                    bgColor={'#2BC155'}
                                                    color={'#fff'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    position={'absolute'}
                                                    bottom={'-5px'}
                                                    right={'-10px'}
                                                    border={'2px solid #fff'}
                                                >
                                                    <TbArrowUpFromArc
                                                        fontSize={'20px'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                Chauffina Carr
                                            </Text>
                                            <Text
                                                fontSize={'14px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                Capital Bank
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                June 1, 2020
                                            </Text>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                08:22 AM
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                +$5,553
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                bgColor={'#2BC155'}
                                                border={'1px solid #2BC155'}
                                                borderRadius={'6px'}
                                                padding={'7px'}
                                                color={'#fff'}
                                                fontSize={'14px'}
                                            >
                                                Completed
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td border={'none'} pl={'0px'}>
                                            <Box
                                                w={'63px'}
                                                h={'63px'}
                                                borderRadius={'50%'}
                                                position={'relative'}
                                                bgColor={'#C4C4C4'}
                                            >
                                                <Box
                                                    w={'36px'}
                                                    h={'36px'}
                                                    borderRadius={'50%'}
                                                    bgColor={'#A93E3E'}
                                                    color={'#fff'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    position={'absolute'}
                                                    bottom={'-5px'}
                                                    right={'-10px'}
                                                    border={'2px solid #fff'}
                                                >
                                                    <TbArrowUpFromArc
                                                        fontSize={'20px'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                Andrew Lee
                                            </Text>
                                            <Text
                                                fontSize={'14px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                Chase Bank
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                June 1, 2020
                                            </Text>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                08:22 AM
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                +$19,658
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                bgColor={'#A93E3E'}
                                                border={'1px solid #A93E3E'}
                                                borderRadius={'6px'}
                                                padding={'7px'}
                                                color={'#fff'}
                                                fontSize={'14px'}
                                            >
                                                Canceled
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td border={'none'} pl={'0px'}>
                                            <Box
                                                w={'63px'}
                                                h={'63px'}
                                                borderRadius={'50%'}
                                                position={'relative'}
                                                bgColor={'#C4C4C4'}
                                            >
                                                <Box
                                                    w={'36px'}
                                                    h={'36px'}
                                                    borderRadius={'50%'}
                                                    bgColor={'#2BC155'}
                                                    color={'#fff'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    position={'absolute'}
                                                    bottom={'-5px'}
                                                    right={'-10px'}
                                                    border={'2px solid #fff'}
                                                >
                                                    <TbArrowUpFromArc
                                                        fontSize={'20px'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                Chauffina Carr
                                            </Text>
                                            <Text
                                                fontSize={'14px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                Capital Bank
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                June 1, 2020
                                            </Text>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                08:22 AM
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                +$5,553
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                bgColor={'#2BC155'}
                                                border={'1px solid #2BC155'}
                                                borderRadius={'6px'}
                                                padding={'7px'}
                                                color={'#fff'}
                                                fontSize={'14px'}
                                            >
                                                Completed
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td border={'none'} pl={'0px'}>
                                            <Box
                                                w={'63px'}
                                                h={'63px'}
                                                borderRadius={'50%'}
                                                position={'relative'}
                                                bgColor={'#C4C4C4'}
                                            >
                                                <Box
                                                    w={'36px'}
                                                    h={'36px'}
                                                    borderRadius={'50%'}
                                                    bgColor={'#2BC155'}
                                                    color={'#fff'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    position={'absolute'}
                                                    bottom={'-5px'}
                                                    right={'-10px'}
                                                    border={'2px solid #fff'}
                                                >
                                                    <TbArrowUpFromArc
                                                        fontSize={'20px'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                Chauffina Carr
                                            </Text>
                                            <Text
                                                fontSize={'14px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                Capital Bank
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                June 1, 2020
                                            </Text>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                color={'#000000'}
                                            >
                                                08:22 AM
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                fontSize={'16px'}
                                                fontWeight={'500'}
                                                color={'#000000'}
                                            >
                                                +$5,553
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                bgColor={'#2BC155'}
                                                border={'1px solid #2BC155'}
                                                borderRadius={'6px'}
                                                padding={'7px'}
                                                color={'#fff'}
                                                fontSize={'14px'}
                                            >
                                                Completed
                                            </Text>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </GridItem>
                <GridItem colSpan={2}>
                    <Box
                        bgColor={'#d9d9d93b'}
                        borderRadius={'24px'}
                        boxShadow={'5px 5px 10px #00000017'}
                        h={'max-content'}
                        padding={'20px'}
                    >
                        <Text
                            textAlign={'center'}
                            color={'#0A2B1B'}
                            fontSize={'18px'}
                            fontWeight={'600'}
                        >
                            Top Bank Transfer Statistics
                        </Text>
                        <Box>
                            <ReactApexChart
                                options={bankTransferChartOptions}
                                series={bankTransferChartSeries}
                                type="donut"
                            />
                            <Grid
                                templateColumns='repeat(2, 1fr)'
                                gap={4}
                            >
                                <GridItem>
                                    <Text
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#1EAAE7',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '-20px'
                                        }}
                                    >Bank Of America</Text>
                                </GridItem>
                                <GridItem>
                                    <Text
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#FF7A30',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '-20px'
                                        }}
                                    >Wells Fargo</Text>
                                </GridItem>
                                <GridItem>
                                    <Text
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#6418C3',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '-20px'
                                        }}
                                    >Capital One</Text>
                                </GridItem>
                                <GridItem>
                                    <Text
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#FFEF5F',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '-20px'
                                        }}
                                    >Goldman Sachs Bank</Text>
                                </GridItem>
                                <GridItem>
                                    <Text
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#2BC155',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '-20px'
                                        }}
                                    >Chase Bank</Text>
                                </GridItem>
                            </Grid>
                        </Box>
                        <Link
                            style={{
                                display: 'block',
                                color: '#0A2B1B',
                                textDecoration: 'underline',
                                margin: '0px',
                                fontSize: '14px',
                                fontWeight: '500',
                                textAlign: 'end'
                            }}
                        >
                            View All
                        </Link>
                    </Box>
                </GridItem>
            </Grid>
        </Box >
    );
}

export default AdminDashboard;
