import { Post } from '../../helpers/apicalls/apicalls';
import * as actionTypes from './generalTypes';

export const getStatesByCountryId = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_STATES_LOADING });
        Post('/api/get-states-by-country-id', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.GET_STATES_SUCCESS, payload: res?.data });
                } else {
                    dispatch({ type: actionTypes.GET_STATES_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.GET_STATES_FAILED });
            });
    }
}