import { Box, Button, FormControl, FormLabel, HStack, Input, InputGroup, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { IoSendSharp } from 'react-icons/io5';

const TransferMoney = () => {
    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Transfer Money | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'20px'}>Transfer Money</Text>
            </HStack>
            <Box display={'flex'} justifyContent={'center'}>
                <Box border={'1px solid #0A2B1B'} borderRadius={'24px'} m={'60px'} p={'40px'} w={'550px'}>
                    <Text mb={'40px'} fontSize={'20px'} fontWeight={'600'} color={'#0A2B1B'}>Enter Transfer Money Details:</Text>
                    <form>
                        <InputGroup mb={'20px'} alignItems={'center'}>
                            <FormLabel w={'60%'} fontSize={'14px'}>Transfer Amount: </FormLabel>
                            <Input
                                bgColor={'#F8F7FC'}
                                borderColor={'#F8F7FC'}
                                _hover={{ borderColor: '#F8F7FC' }}
                                color={'#9A999C'}
                            />
                        </InputGroup>
                        <InputGroup mb={'20px'} alignItems={'center'}>
                            <FormLabel w={'60%'} fontSize={'14px'}>Transfer Money From: </FormLabel>
                            <Select
                                placeholder='Select card'
                                bgColor={'#F8F7FC'}
                                borderColor={'#F8F7FC'}
                                _hover={{ borderColor: '#F8F7FC' }}
                                color={'#9A999C'}
                            >
                                <option value='option1'>Card 1</option>
                                <option value='option2'>Card 2</option>
                                <option value='option3'>Card 3</option>
                            </Select>
                        </InputGroup>
                        <InputGroup alignItems={'center'}>
                            <FormLabel w={'60%'} fontSize={'14px'}>Transfer Money To: </FormLabel>
                            <Select
                                placeholder='Select Sender'
                                bgColor={'#F8F7FC'}
                                borderColor={'#F8F7FC'}
                                _hover={{ borderColor: '#F8F7FC' }}
                                color={'#9A999C'}
                            >
                                <option value='option1'>Sender 1</option>
                                <option value='option2'>Sender 2</option>
                                <option value='option3'>Sender 3</option>
                            </Select>
                        </InputGroup>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'50px'}>
                            <Button
                                backgroundColor={'#0A2B1B'}
                                borderRadius={'50px'}
                                padding={'10px 10px 10px 10px'}
                                color={'#fff'}
                                border={'1px solid #0A2B1B'}
                                display="inline-flex"
                                alignItems="center"
                                className="group"
                                transition={'all 0.3s ease-in-out'}
                                _hover={{
                                    backgroundColor: '#fff',
                                    color: '#0A2B1B',
                                    border: '1px solid #0A2B1B'
                                }}
                            >
                                <Box
                                    bgColor={'#fff'}
                                    color={'#0A2B1B'}
                                    borderRadius={'50%'}
                                    w={'25px'}
                                    h={'25px'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    transition={'all 0.3s ease-in-out'}
                                    mr={2}
                                    _groupHover={{
                                        bgColor: '#0A2B1B',
                                        color: '#fff',
                                    }}
                                >
                                    <IoSendSharp />
                                </Box>
                                Save Details
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
}

export default TransferMoney;
