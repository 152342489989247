import { AddIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, HStack, Image, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Skeleton, SkeletonCircle, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaCheck, FaEllipsisV, FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/images/no-image.png';
import { LuPhone } from 'react-icons/lu';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { BsCreditCard2Back } from 'react-icons/bs';
import { AiOutlineBank } from 'react-icons/ai';
import { FiChevronsRight } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMoneySender, getMoneySendersList } from '../../redux/moneySender/moneySenderActions';

const SenderList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const user = useSelector(state => state?.user?.user);
    const token = useSelector(state => state?.user?.user?.token);
    const moneySenders = useSelector(state => state?.moneySender?.moneySenders);
    const isGetMoneySendersLoading = useSelector(state => state?.moneySender?.isGetMoneySenderLoading);
    const isMoneySenderDeletingLoading = useSelector(state => state?.moneySender?.isMoneySenderDeleteLoading);
    const [deleteMoneySenderId, setDeleteMoneySenderId] = useState(null);

    useEffect(() => {
        getMoneySenders();
    }, []);

    const getMoneySenders = () => {
        const data = {
            user_id: user?.user?.id
        };
        dispatch(getMoneySendersList(data, token));
    }

    const onEdit = (id) => {
        navigate('/money-sender/edit/' + btoa(id))
    }

    const openDeleteModal = (id) => {
        setDeleteMoneySenderId(id);
        onOpen();
    }

    const onDeleteCard = (id) => {
        const data = {
            id: deleteMoneySenderId
        };
        dispatch(deleteMoneySender(data, token, afterSuccess));
    }

    const afterSuccess = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top'
        });
        onClose();
        getMoneySenders();
    }

    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Money Sender | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'20px'}>Money Sender List</Text>
                <Button
                    backgroundColor={'#2BC155'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #2BC155'}
                    display="inline-flex"
                    alignItems="center"
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#2BC155',
                        border: '1px solid #2BC155'
                    }}
                    onClick={() => navigate('/money-sender/add')}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#2BC155'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#2BC155',
                            color: '#fff',
                        }}
                    >
                        <AddIcon fontSize={'13px'} />
                    </Box>
                    Add Money Sender
                </Button>
            </HStack>
            <Box mt={'30px'}>
                {isGetMoneySendersLoading ?
                    <SimpleGrid columns={3} spacing={8}>
                        <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                            <HStack alignItems={'center'} justifyContent={'space-between'}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                        <SkeletonCircle w={'68px'} h={'68px'}>
                                            <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                        </SkeletonCircle>
                                    </Box>
                                    <Box>
                                        <Skeleton>
                                            <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                        </Skeleton>
                                    </Box>
                                </Box>
                                <Skeleton>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Skeleton>
                            </HStack>
                            <Box mt={'40px'}>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                                    </Skeleton>
                                </HStack>
                                <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Skeleton>
                                        <Button
                                            backgroundColor={'#0A2B1B'}
                                            borderRadius={'50px'}
                                            padding={'10px 10px 10px 10px'}
                                            color={'#fff'}
                                            border={'1px solid #0A2B1B'}
                                            display="inline-flex"
                                            alignItems="center"
                                            className="group"
                                            transition={'all 0.3s ease-in-out'}
                                            _hover={{
                                                backgroundColor: '#fff',
                                                color: '#0A2B1B',
                                                border: '1px solid #0A2B1B'
                                            }}
                                            onClick={() => navigate('/add-money-sender')}
                                        >
                                            <Box
                                                bgColor={'#fff'}
                                                color={'#0A2B1B'}
                                                borderRadius={'50%'}
                                                w={'25px'}
                                                h={'25px'}
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                transition={'all 0.3s ease-in-out'}
                                                mr={2}
                                                _groupHover={{
                                                    bgColor: '#0A2B1B',
                                                    color: '#fff',
                                                }}
                                            >
                                                <AddIcon fontSize={'13px'} />
                                            </Box>
                                            Transfer Money
                                        </Button>
                                    </Skeleton>
                                </Box>
                            </Box>
                        </Box>
                        <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                            <HStack alignItems={'center'} justifyContent={'space-between'}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                        <SkeletonCircle w={'68px'} h={'68px'}>
                                            <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                        </SkeletonCircle>
                                    </Box>
                                    <Box>
                                        <Skeleton>
                                            <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                        </Skeleton>
                                    </Box>
                                </Box>
                                <Skeleton>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Skeleton>
                            </HStack>
                            <Box mt={'40px'}>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                                    </Skeleton>
                                </HStack>
                                <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Skeleton>
                                        <Button
                                            backgroundColor={'#0A2B1B'}
                                            borderRadius={'50px'}
                                            padding={'10px 10px 10px 10px'}
                                            color={'#fff'}
                                            border={'1px solid #0A2B1B'}
                                            display="inline-flex"
                                            alignItems="center"
                                            className="group"
                                            transition={'all 0.3s ease-in-out'}
                                            _hover={{
                                                backgroundColor: '#fff',
                                                color: '#0A2B1B',
                                                border: '1px solid #0A2B1B'
                                            }}
                                            onClick={() => navigate('/add-money-sender')}
                                        >
                                            <Box
                                                bgColor={'#fff'}
                                                color={'#0A2B1B'}
                                                borderRadius={'50%'}
                                                w={'25px'}
                                                h={'25px'}
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                transition={'all 0.3s ease-in-out'}
                                                mr={2}
                                                _groupHover={{
                                                    bgColor: '#0A2B1B',
                                                    color: '#fff',
                                                }}
                                            >
                                                <AddIcon fontSize={'13px'} />
                                            </Box>
                                            Transfer Money
                                        </Button>
                                    </Skeleton>
                                </Box>
                            </Box>
                        </Box>
                        <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                            <HStack alignItems={'center'} justifyContent={'space-between'}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                        <SkeletonCircle w={'68px'} h={'68px'}>
                                            <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                        </SkeletonCircle>
                                    </Box>
                                    <Box>
                                        <Skeleton>
                                            <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                        </Skeleton>
                                    </Box>
                                </Box>
                                <Skeleton>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                            <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Skeleton>
                            </HStack>
                            <Box mt={'40px'}>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                                    </Skeleton>
                                </HStack>
                                <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                    <Skeleton>
                                        <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                    </Skeleton>
                                    <Skeleton>
                                        <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                                    </Skeleton>
                                </HStack>
                                <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Skeleton>
                                        <Button
                                            backgroundColor={'#0A2B1B'}
                                            borderRadius={'50px'}
                                            padding={'10px 10px 10px 10px'}
                                            color={'#fff'}
                                            border={'1px solid #0A2B1B'}
                                            display="inline-flex"
                                            alignItems="center"
                                            className="group"
                                            transition={'all 0.3s ease-in-out'}
                                            _hover={{
                                                backgroundColor: '#fff',
                                                color: '#0A2B1B',
                                                border: '1px solid #0A2B1B'
                                            }}
                                            onClick={() => navigate('/add-money-sender')}
                                        >
                                            <Box
                                                bgColor={'#fff'}
                                                color={'#0A2B1B'}
                                                borderRadius={'50%'}
                                                w={'25px'}
                                                h={'25px'}
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                transition={'all 0.3s ease-in-out'}
                                                mr={2}
                                                _groupHover={{
                                                    bgColor: '#0A2B1B',
                                                    color: '#fff',
                                                }}
                                            >
                                                <AddIcon fontSize={'13px'} />
                                            </Box>
                                            Transfer Money
                                        </Button>
                                    </Skeleton>
                                </Box>
                            </Box>
                        </Box>
                    </SimpleGrid>
                    :
                    <SimpleGrid columns={3} spacing={8}>
                        {moneySenders?.length > 0 ? moneySenders.map((moneySender, index) => {
                            let limit = moneySender?.account_number?.length - 6;
                            let stars = '';
                            for (var i = 0; i < limit; i++) {
                                stars = stars + '*';
                            }
                            const accountNumber = moneySender?.account_number.slice(0, 2) + stars + moneySender?.account_number.slice(-4);
                            return (
                                <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'} key={index}>
                                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Box w={'68px'} h={'68px'} overflow={'hidden'} mr={'10px'}>
                                                <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                            </Box>
                                            <Box>
                                                <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>{moneySender?.first_name} {moneySender?.last_name}</Text>
                                                {/* <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text> */}
                                            </Box>
                                        </Box>
                                        <Menu>
                                            <MenuButton>
                                                <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                            </MenuButton>
                                            <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                <MenuItem
                                                    transition={'all 0.3s ease-in-out'}
                                                    _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                    _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                                    onClick={() => onEdit(moneySender?.id)}
                                                >
                                                    Edit
                                                </MenuItem>
                                                <MenuItem
                                                    transition={'all 0.3s ease-in-out'}
                                                    _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                    onClick={() => openDeleteModal(moneySender?.id)}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </HStack>
                                    <Box mt={'40px'}>
                                        <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                            <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                            <Text fontSize={'14px'} color={'#222132'}>{moneySender?.phone}</Text>
                                        </HStack>
                                        <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                            <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                            <Text fontSize={'14px'} color={'#222132'}>{moneySender?.email}</Text>
                                        </HStack>
                                        <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                            <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                            <Text fontSize={'14px'} color={'#222132'}>Account Number: {accountNumber}</Text>
                                        </HStack>
                                        <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                            <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                            <Text fontSize={'14px'} color={'#222132'}>Bank Name: {moneySender?.bank_name}</Text>
                                        </HStack>
                                        <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            <Button
                                                backgroundColor={'#0A2B1B'}
                                                borderRadius={'50px'}
                                                padding={'10px 10px 10px 10px'}
                                                color={'#fff'}
                                                border={'1px solid #0A2B1B'}
                                                display="inline-flex"
                                                alignItems="center"
                                                className="group"
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{
                                                    backgroundColor: '#fff',
                                                    color: '#0A2B1B',
                                                    border: '1px solid #0A2B1B'
                                                }}
                                                onClick={() => navigate('/add-money-sender')}
                                            >
                                                <Box
                                                    bgColor={'#fff'}
                                                    color={'#0A2B1B'}
                                                    borderRadius={'50%'}
                                                    w={'25px'}
                                                    h={'25px'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    transition={'all 0.3s ease-in-out'}
                                                    mr={2}
                                                    _groupHover={{
                                                        bgColor: '#0A2B1B',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    <AddIcon fontSize={'13px'} />
                                                </Box>
                                                Transfer Money
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        }) : null}

                        {/* <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68'} h={'68'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                    <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                            <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    backgroundColor={'#0A2B1B'}
                                    borderRadius={'50px'}
                                    padding={'10px 10px 10px 10px'}
                                    color={'#fff'}
                                    border={'1px solid #0A2B1B'}
                                    display="inline-flex"
                                    alignItems="center"
                                    className="group"
                                    transition={'all 0.3s ease-in-out'}
                                    _hover={{
                                        backgroundColor: '#fff',
                                        color: '#0A2B1B',
                                        border: '1px solid #0A2B1B'
                                    }}
                                    onClick={() => navigate('/add-money-sender')}
                                >
                                    <Box
                                        bgColor={'#fff'}
                                        color={'#0A2B1B'}
                                        borderRadius={'50%'}
                                        w={'25px'}
                                        h={'25px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        transition={'all 0.3s ease-in-out'}
                                        mr={2}
                                        _groupHover={{
                                            bgColor: '#0A2B1B',
                                            color: '#fff',
                                        }}
                                    >
                                        <AddIcon fontSize={'13px'} />
                                    </Box>
                                    Transfer Money
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68'} h={'68'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                    <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                            <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    backgroundColor={'#0A2B1B'}
                                    borderRadius={'50px'}
                                    padding={'10px 10px 10px 10px'}
                                    color={'#fff'}
                                    border={'1px solid #0A2B1B'}
                                    display="inline-flex"
                                    alignItems="center"
                                    className="group"
                                    transition={'all 0.3s ease-in-out'}
                                    _hover={{
                                        backgroundColor: '#fff',
                                        color: '#0A2B1B',
                                        border: '1px solid #0A2B1B'
                                    }}
                                    onClick={() => navigate('/add-money-sender')}
                                >
                                    <Box
                                        bgColor={'#fff'}
                                        color={'#0A2B1B'}
                                        borderRadius={'50%'}
                                        w={'25px'}
                                        h={'25px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        transition={'all 0.3s ease-in-out'}
                                        mr={2}
                                        _groupHover={{
                                            bgColor: '#0A2B1B',
                                            color: '#fff',
                                        }}
                                    >
                                        <AddIcon fontSize={'13px'} />
                                    </Box>
                                    Transfer Money
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68'} h={'68'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                    <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                            <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    backgroundColor={'#0A2B1B'}
                                    borderRadius={'50px'}
                                    padding={'10px 10px 10px 10px'}
                                    color={'#fff'}
                                    border={'1px solid #0A2B1B'}
                                    display="inline-flex"
                                    alignItems="center"
                                    className="group"
                                    transition={'all 0.3s ease-in-out'}
                                    _hover={{
                                        backgroundColor: '#fff',
                                        color: '#0A2B1B',
                                        border: '1px solid #0A2B1B'
                                    }}
                                    onClick={() => navigate('/add-money-sender')}
                                >
                                    <Box
                                        bgColor={'#fff'}
                                        color={'#0A2B1B'}
                                        borderRadius={'50%'}
                                        w={'25px'}
                                        h={'25px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        transition={'all 0.3s ease-in-out'}
                                        mr={2}
                                        _groupHover={{
                                            bgColor: '#0A2B1B',
                                            color: '#fff',
                                        }}
                                    >
                                        <AddIcon fontSize={'13px'} />
                                    </Box>
                                    Transfer Money
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68'} h={'68'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                    <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                            <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    backgroundColor={'#0A2B1B'}
                                    borderRadius={'50px'}
                                    padding={'10px 10px 10px 10px'}
                                    color={'#fff'}
                                    border={'1px solid #0A2B1B'}
                                    display="inline-flex"
                                    alignItems="center"
                                    className="group"
                                    transition={'all 0.3s ease-in-out'}
                                    _hover={{
                                        backgroundColor: '#fff',
                                        color: '#0A2B1B',
                                        border: '1px solid #0A2B1B'
                                    }}
                                    onClick={() => navigate('/add-money-sender')}
                                >
                                    <Box
                                        bgColor={'#fff'}
                                        color={'#0A2B1B'}
                                        borderRadius={'50%'}
                                        w={'25px'}
                                        h={'25px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        transition={'all 0.3s ease-in-out'}
                                        mr={2}
                                        _groupHover={{
                                            bgColor: '#0A2B1B',
                                            color: '#fff',
                                        }}
                                    >
                                        <AddIcon fontSize={'13px'} />
                                    </Box>
                                    Transfer Money
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box bgColor='#EEEEEE' borderRadius={'24px'} p={'20px'}>
                        <HStack alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box w={'68'} h={'68'} overflow={'hidden'} mr={'10px'}>
                                    <Image src={noImage} w={'100%'} h={'100%'} objectFit={'contain'} />
                                </Box>
                                <Box>
                                    <Text fontSize={'18px'} fontWeight={'600'} color={'#0A2B1B'}>Karen Hope</Text>
                                    <Text fontSize={'14px'} color={'#9BABC5'}>Lorem Company</Text>
                                </Box>
                            </Box>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisV fontSize={'25px'} color={'#9BABC5'} />
                                </MenuButton>
                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box mt={'40px'}>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <LuPhone color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>+123 456 7890</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <PiEnvelopeSimple color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>email@mail.com</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <BsCreditCard2Back color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Account Number: 01**********2345</Text>
                            </HStack>
                            <HStack m={'20px 0px 20px 0px'} alignItems={'center'}>
                                <AiOutlineBank color={'#9BABC5'} fontSize={'18px'} />
                                <Text fontSize={'14px'} color={'#222132'}>Bank Name: Chase Bank</Text>
                            </HStack>
                            <Box mt={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    backgroundColor={'#0A2B1B'}
                                    borderRadius={'50px'}
                                    padding={'10px 10px 10px 10px'}
                                    color={'#fff'}
                                    border={'1px solid #0A2B1B'}
                                    display="inline-flex"
                                    alignItems="center"
                                    className="group"
                                    transition={'all 0.3s ease-in-out'}
                                    _hover={{
                                        backgroundColor: '#fff',
                                        color: '#0A2B1B',
                                        border: '1px solid #0A2B1B'
                                    }}
                                    onClick={() => navigate('/add-money-sender')}
                                >
                                    <Box
                                        bgColor={'#fff'}
                                        color={'#0A2B1B'}
                                        borderRadius={'50%'}
                                        w={'25px'}
                                        h={'25px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        transition={'all 0.3s ease-in-out'}
                                        mr={2}
                                        _groupHover={{
                                            bgColor: '#0A2B1B',
                                            color: '#fff',
                                        }}
                                    >
                                        <AddIcon fontSize={'13px'} />
                                    </Box>
                                    Transfer Money
                                </Button>
                            </Box>
                        </Box>
                    </Box> */}
                    </SimpleGrid>
                }
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Button
                        variant={'link'}
                        mt={'20px'}
                        color={'#0A2B1B'}
                        fontSize={'14px'}
                        textDecoration={'underline'}
                        _hover={{
                            color: '#2BB673'
                        }}
                    >
                        Load More <FiChevronsRight style={{ marginLeft: '5px' }} />
                    </Button>
                </Box>
            </Box>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <FaTrash />
                        <Text ml={'10px'}>Delete Money Sender?</Text>
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Are you sure you want to delete this money sender?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            backgroundColor={'#2BC155'}
                            borderRadius={'50px'}
                            padding={'10px 10px 10px 10px'}
                            color={'#fff'}
                            border={'1px solid #2BC155'}
                            display={'inline-flex'}
                            alignItems={'center'}
                            className="group"
                            transition={'all 0.3s ease-in-out'}
                            _hover={{
                                backgroundColor: '#fff',
                                color: '#2BC155',
                                border: '1px solid #2BC155'
                            }}
                            onClick={onClose}
                        >
                            <Box
                                bgColor={'#fff'}
                                color={'#2BC155'}
                                borderRadius={'50%'}
                                w={'25px'}
                                h={'25px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                transition={'all 0.3s ease-in-out'}
                                mr={2}
                                _groupHover={{
                                    bgColor: '#2BC155',
                                    color: '#fff',
                                }}
                            >
                                <FaTimes />
                            </Box>
                            No
                        </Button>
                        <Button
                            ml={3}
                            isLoading={isMoneySenderDeletingLoading}
                            backgroundColor={'#0A2B1B'}
                            borderRadius={'50px'}
                            padding={'10px 10px 10px 10px'}
                            color={'#fff'}
                            border={'1px solid #0A2B1B'}
                            display={'inline-flex'}
                            alignItems={'center'}
                            className="group"
                            transition={'all 0.3s ease-in-out'}
                            _hover={{
                                backgroundColor: '#fff',
                                color: '#0A2B1B',
                                border: '1px solid #0A2B1B'
                            }}
                            onClick={onDeleteCard}
                        >
                            <Box
                                bgColor={'#fff'}
                                color={'#0A2B1B'}
                                borderRadius={'50%'}
                                w={'25px'}
                                h={'25px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                transition={'all 0.3s ease-in-out'}
                                mr={2}
                                _groupHover={{
                                    bgColor: '#0A2B1B',
                                    color: '#fff',
                                }}
                            >
                                <FaCheck />
                            </Box>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
}

export default SenderList;
