import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Img, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BsCreditCard2Back } from 'react-icons/bs';
import { FaCaretDown, FaEllipsisV, FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { TbArrowDownFromArc, TbArrowDownToArc } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/images/no-image.png';
import { BiFilter } from 'react-icons/bi';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';

const AdminBankTransferList = () => {
    const navigate = useNavigate();
    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Bank Transfer | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'18px'}>Top Bank Transfer List</Text>
                <Button
                    backgroundColor={'#2BC155'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #2BC155'}
                    display="inline-flex"
                    alignItems="center"
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#2BC155',
                        border: '1px solid #2BC155'
                    }}
                    onClick={() => navigate(-1)}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#2BC155'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#2BC155',
                            color: '#fff',
                        }}
                    >
                        <BiFilter />
                    </Box>
                    Advance Search
                </Button>
            </HStack>
            <Box mt={'20px'}>
                <TableContainer>
                    <Table variant='simple'>
                        <Tbody>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#1EAAE7',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Bank of America
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#A93E3E'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Andrew Lee</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#FF7A30',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Wells Fargo
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$19,658</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>VISA Card 404</Text>
                                </Td>
                                <Td><Text bgColor={'#A93E3E'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Canceled</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#DE5B11'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#6418C3',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Capital One
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#DE5B11'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Pending</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#2BC155',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Chase Bank
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#1EAAE7',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Bank of America
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#A93E3E'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Andrew Lee</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#FEF072',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Goldman Sachs Bank
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$19,658</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>VISA Card 404</Text>
                                </Td>
                                <Td><Text bgColor={'#A93E3E'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Canceled</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#DE5B11'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#FF7A30',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Wells Fargo
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#DE5B11'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Pending</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Box
                                        position={'relative'}
                                        w={'63px'}
                                        h={'63px'}
                                        borderRadius={'50%'}
                                        bgColor={'#C4C4C4'}
                                    >
                                        <Box
                                            w={'36px'}
                                            h={'36px'}
                                            position={'absolute'}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            border={'2px solid #fff'}
                                            bgColor={'#2BC155'}
                                            color={'#fff'}
                                            borderRadius={'50%'}
                                            bottom={'-5px'}
                                            right={'-10px'}
                                        >
                                            <TbArrowDownToArc />
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'} mb={'5px'}>Chauffina Carr</Text>
                                    <Text fontSize={'14px'} color={'#000000cc'}>June 1, 2020, 08:22 AM</Text>
                                </Td>
                                <Td>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#000000'}
                                        position={'relative'}
                                        ml={'20px'}
                                        _before={{
                                            content: '""',
                                            w: '15px',
                                            h: '15px',
                                            borderRadius: '50%',
                                            bgColor: '#6418C3',
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-23px'
                                        }}
                                    >
                                        Capital One
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>+$5,553</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'16px'} fontWeight={'500'} color={'#000000'}>MasterCard 404</Text>
                                </Td>
                                <Td><Text bgColor={'#2BC155'} color={'#fff'} p={'10px'} borderRadius={'6px'} fontSize={'14px'}>Completed</Text></Td>
                                <Td>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisV fontSize={'24px'} color={'#070C23'} />
                                        </MenuButton>
                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                transition={'all 0.3s ease-in-out'}
                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                <Box
                    mt={'20px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                >
                    <Box
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        <HiOutlineArrowNarrowLeft />
                    </Box>
                    <Text
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#fff'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#2bc155'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        1
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        2
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        3
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        4
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        5
                    </Text>
                    <Box
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#2bc155'}
                        cursor={'pointer'}
                        w={'32px'}
                        h={'32px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgColor={'#fff'}
                        borderRadius={'3px'}
                        border={'1px solid #2bc155'}
                        m={'0px 5px'}
                    >
                        <HiOutlineArrowNarrowRight />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AdminBankTransferList;
