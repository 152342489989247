import * as actionTypes from './cardTypes';

const initialState = {
    isCardSubmitLoading: false,
    isCardUpdatingLoading: false,
    isCardDeletingLoading: false,
    isGetCardsLoading: false,
    isGetCardByIdLoading: false,
    cards: [],
    cardForEdit: {}
};

const cardReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_CARD_SUBMIT_LOADING:
            return {
                ...state,
                isCardSubmitLoading: true
            };
        case actionTypes.IS_CARD_SUBMIT_SUCCESS:
            return {
                ...state,
                isCardSubmitLoading: false
            };
        case actionTypes.IS_CARD_SUBMIT_FAILED:
            return {
                ...state,
                isCardSubmitLoading: false
            };
        case actionTypes.IS_GET_CARD_LOADING:
            return {
                ...state,
                isGetCardsLoading: true
            };
        case actionTypes.IS_GET_CARD_SUCCESS:
            return {
                ...state,
                isGetCardsLoading: false,
                cards: payload
            };
        case actionTypes.IS_GET_CARD_FAILED:
            return {
                ...state,
                isGetCardsLoading: false
            };
        case actionTypes.IS_GET_CARD_BY_ID_LOADING:
            return {
                ...state,
                isGetCardByIdLoading: true
            };
        case actionTypes.IS_GET_CARD_BY_ID_SUCCESS:
            return {
                ...state,
                isGetCardByIdLoading: false,
                cardForEdit: payload
            };
        case actionTypes.IS_GET_CARD_BY_ID_FAILED:
            return {
                ...state,
                isGetCardByIdLoading: false
            };
        case actionTypes.IS_CARD_UPDATING_LOADING:
            return {
                ...state,
                isCardUpdatingLoading: true
            };
        case actionTypes.IS_CARD_UPDATING_SUCCESS:
            return {
                ...state,
                isCardUpdatingLoading: false,
                cardForEdit: payload
            };
        case actionTypes.IS_CARD_UPDATING_FAILED:
            return {
                ...state,
                isCardUpdatingLoading: false
            };
        case actionTypes.IS_CARD_DELETE_LOADING:
            return {
                ...state,
                isCardDeletingLoading: true
            };
        case actionTypes.IS_CARD_DELETE_SUCCESS:
            return {
                ...state,
                isCardDeletingLoading: false
            };
        case actionTypes.IS_CARD_DELETE_FAILED:
            return {
                ...state,
                isCardDeletingLoading: false
            };
        default:
            return state;
    }
};

export default cardReducer;