import React from 'react';
import { Box, Button, FormControl, FormLabel, Grid, GridItem, HStack, Input, Text, useToast } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { LiaSaveSolid } from 'react-icons/lia';
import { TiArrowBackOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

const ClientsEdit = () => {
    const navigate = useNavigate();
    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Clients Edit | Remitless</title>
            </Helmet>
            <HStack justifyContent={'space-between'}>
                <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'20px'}>Clients Edit</Text>
                <Button
                    backgroundColor={'#2BC155'}
                    borderRadius={'50px'}
                    padding={'10px 10px 10px 10px'}
                    color={'#fff'}
                    border={'1px solid #2BC155'}
                    display="inline-flex"
                    alignItems="center"
                    className="group"
                    transition={'all 0.3s ease-in-out'}
                    _hover={{
                        backgroundColor: '#fff',
                        color: '#2BC155',
                        border: '1px solid #2BC155'
                    }}
                    onClick={() => navigate(-1)}
                >
                    <Box
                        bgColor={'#fff'}
                        color={'#2BC155'}
                        borderRadius={'50%'}
                        w={'25px'}
                        h={'25px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        transition={'all 0.3s ease-in-out'}
                        mr={2}
                        _groupHover={{
                            bgColor: '#2BC155',
                            color: '#fff',
                        }}
                    >
                        <TiArrowBackOutline />
                    </Box>
                    back
                </Button>
            </HStack>
            <Box border={'1px solid #0A2B1B'} borderRadius={'24px'} m={'60px'} p={'40px'}>
                <form>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    bgColor={'#F8F7FC'}
                                    borderColor={'#F8F7FC'}
                                    _hover={{ borderColor: '#F8F7FC' }}
                                    color={'#9A999C'}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    bgColor={'#F8F7FC'}
                                    borderColor={'#F8F7FC'}
                                    _hover={{ borderColor: '#F8F7FC' }}
                                    color={'#9A999C'}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    bgColor={'#F8F7FC'}
                                    borderColor={'#F8F7FC'}
                                    _hover={{ borderColor: '#F8F7FC' }}
                                    color={'#9A999C'}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Telephone</FormLabel>
                                <Input
                                    bgColor={'#F8F7FC'}
                                    borderColor={'#F8F7FC'}
                                    _hover={{ borderColor: '#F8F7FC' }}
                                    color={'#9A999C'}
                                />
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'50px'}>
                        <Button
                            type='submit'
                            backgroundColor={'#0A2B1B'}
                            borderRadius={'50px'}
                            padding={'10px 10px 10px 10px'}
                            color={'#fff'}
                            border={'1px solid #0A2B1B'}
                            display="inline-flex"
                            alignItems="center"
                            className="group"
                            transition={'all 0.3s ease-in-out'}
                            _hover={{
                                backgroundColor: '#fff',
                                color: '#0A2B1B',
                                border: '1px solid #0A2B1B'
                            }}
                        >
                            <Box
                                bgColor={'#fff'}
                                color={'#0A2B1B'}
                                borderRadius={'50%'}
                                w={'25px'}
                                h={'25px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                transition={'all 0.3s ease-in-out'}
                                mr={2}
                                _groupHover={{
                                    bgColor: '#0A2B1B',
                                    color: '#fff',
                                }}
                            >
                                <LiaSaveSolid />
                            </Box>
                            Save Details
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default ClientsEdit;