import { AddIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, HStack, Img, Menu, MenuButton, MenuItem, MenuList, Skeleton, Table, TableContainer, Tbody, Td, Text, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import cardImage from '../../assets/images/card-design.png';
import card1 from '../../assets/images/card-1.png';
import card2 from '../../assets/images/card-2.png';
import card3 from '../../assets/images/card-3.png';
import card4 from '../../assets/images/card-4.png';
import { FaCheck, FaEllipsisV, FaTimes, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCard, getCards } from '../../redux/card/cardActions';

const CardsCenter = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const token = useSelector(state => state?.user?.user?.token);
    const isLoading = useSelector(state => state?.card?.isGetCardsLoading);
    const isCardDeletingLoading = useSelector(state => state?.card?.isCardDeletingLoading);
    const cards = useSelector(state => state?.card?.cards);
    const [deleteCardId, setDeleteCardId] = useState(null);

    useEffect(() => {
        getCardsData();
    }, []);

    const getCardsData = () => {
        dispatch(getCards(token));
    }

    const cardImages = [card1, card2, card3, card4];

    const getRandomIndex = (cardImages) => {
        return Math.floor(Math.random() * cardImages.length);
    };

    const editCard = (id) => {
        navigate('/cards-center/edit-card/' + btoa(id));
    }

    const openDeleteModal = (id) => {
        setDeleteCardId(id);
        onOpen();
    }

    const onDeleteCard = () => {
        const data = {
            id: deleteCardId
        };
        dispatch(deleteCard(data, token, afterSuccess));
    }

    const afterSuccess = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top'
        });
        onClose();
        getCardsData();
    }

    return (
        <Box bg={'#fff'} boxShadow={'5px 5px 10px #00000017'} h={'max-content'} padding={'20px'}>
            <Helmet>
                <title>Cards Center | Remitless</title>
            </Helmet>
            {/* <HStack p={'20px'} alignItems={'center'} justifyContent={'flex-start'}>
                <Box textAlign={'center'}>
                    <Text color={'#0A2B1B'} fontSize={'18px'} fontWeight={'600'} mb={'25px'}>My Wallet</Text>
                    <Box bgImage={cardImage} bgRepeat={'no-repeat'} bgSize={'cover'} w={'400px'} h={'250px'} p={'35px'} borderRadius={'24px'}>
                        <Box>
                            <Text color={'#fff'} fontSize={'17px'}>Card 1</Text>
                            <Text color={'#fff'} fontSize={'36px'} fontWeight={'600'}>$673,412.66</Text>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mt={'25px'}>
                            <Text color={'#fff'} fontSize={'20px'}>**** **** **** 1234</Text>
                        </Box>
                        <HStack alignItems={'flex-end'} justifyContent={'space-between'} maxHeight={'100%'} mt={'10px'}>
                            <Box>
                                <Text color={'#ffffff99'} fontSize={'14px'}>Valid Thru</Text>
                                <Text color={'#fff'} fontSize={'16px'}>08/21</Text>
                            </Box>
                            <Box>
                                <Text color={'#ffffff99'} fontSize={'14px'}>Card Holder</Text>
                                <Text color={'#fff'} fontSize={'16px'}>Garcia Jr.</Text>
                            </Box>
                        </HStack>
                    </Box>
                </Box>
                <Box textAlign={'center'}>
                    <Text color={'#0A2B1B'} fontSize={'18px'} fontWeight={'600'} mb={'25px'}>Card Statistics</Text>
                    <Box bgImage={cardImage} bgRepeat={'no-repeat'} bgSize={'cover'} w={'400px'} h={'250px'} p={'35px'}>
                        <Box>
                            <Text color={'#fff'} fontSize={'17px'}>Card 1</Text>
                            <Text color={'#fff'} fontSize={'36px'} fontWeight={'600'}>$673,412.66</Text>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mt={'25px'}>
                            <Text color={'#fff'} fontSize={'20px'}>**** **** **** 1234</Text>
                        </Box>
                        <HStack alignItems={'flex-end'} justifyContent={'space-between'} maxHeight={'100%'} mt={'10px'}>
                            <Box>
                                <Text color={'#ffffff99'} fontSize={'14px'}>Valid Thru</Text>
                                <Text color={'#fff'} fontSize={'16px'}>08/21</Text>
                            </Box>
                            <Box>
                                <Text color={'#ffffff99'} fontSize={'14px'}>Card Holder</Text>
                                <Text color={'#fff'} fontSize={'16px'}>Garcia Jr.</Text>
                            </Box>
                        </HStack>
                    </Box>
                </Box>
            </HStack> */}
            <Box bgColor={'#d9d9d93b'} borderRadius={'24px'} mt={'20px'} p={'20px'}>
                <HStack justifyContent={'space-between'}>
                    <Text color={'#0A2B1B'} fontWeight={'600'} fontSize={'18px'}>My Card List</Text>
                    <Button
                        backgroundColor={'#2BC155'}
                        borderRadius={'50px'}
                        padding={'10px 10px 10px 10px'}
                        color={'#fff'}
                        border={'1px solid #2BC155'}
                        display={'inline-flex'}
                        alignItems={'center'}
                        className="group"
                        transition={'all 0.3s ease-in-out'}
                        _hover={{
                            backgroundColor: '#fff',
                            color: '#2BC155',
                            border: '1px solid #2BC155'
                        }}
                        onClick={() => navigate('/cards-center/add-new-card')}
                    >
                        <Box
                            bgColor={'#fff'}
                            color={'#2BC155'}
                            borderRadius={'50%'}
                            w={'25px'}
                            h={'25px'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            transition={'all 0.3s ease-in-out'}
                            mr={2}
                            _groupHover={{
                                bgColor: '#2BC155',
                                color: '#fff',
                            }}
                        >
                            <AddIcon fontSize={'13px'} />
                        </Box>
                        Add New Card
                    </Button>
                </HStack>
                {isLoading ?
                    <TableContainer>
                        <Table variant='simple'>
                            <Tbody>
                                <Tr
                                    borderBottom="1px solid #000000"
                                    _last={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <Td border={'none'} pl={'0px'}>
                                        <Skeleton>
                                            <Img src={cardImages[getRandomIndex(cardImages)]} />
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Primary</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Capital One Bank</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Paul Beasley</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'} pr={'0px'}>
                                        <Skeleton>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                        _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Skeleton>
                                    </Td>
                                </Tr>
                                <Tr
                                    borderBottom="1px solid #000000"
                                    _last={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <Td border={'none'} pl={'0px'}>
                                        <Skeleton>
                                            <Img src={cardImages[getRandomIndex(cardImages)]} />
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Primary</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Capital One Bank</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Paul Beasley</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'} pr={'0px'}>
                                        <Skeleton>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                        _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Skeleton>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td border={'none'} pl={'0px'}>
                                        <Skeleton>
                                            <Img src={cardImages[getRandomIndex(cardImages)]} />
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Primary</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Capital One Bank</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                        </Skeleton>
                                        <Skeleton>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Paul Beasley</Text>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'}>
                                        <Skeleton>
                                            <Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link>
                                        </Skeleton>
                                    </Td>
                                    <Td border={'none'} pr={'0px'}>
                                        <Skeleton>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                        _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        transition={'all 0.3s ease-in-out'}
                                                        _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Skeleton>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                    :
                    cards?.length > 0 ?
                        <TableContainer>
                            <Table variant='simple'>
                                <Tbody>
                                    {cards?.map((card, index) => {
                                        return (
                                            <Tr
                                                key={index}
                                                borderBottom="1px solid #000000"
                                                _last={{
                                                    borderBottom: "none"
                                                }}
                                            >
                                                <Td border={'none'} pl={'0px'} pr={'0px'}><Img src={cardImages[getRandomIndex(cardImages)]} /></Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}>
                                                    <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>{card?.type == 1 ? 'Primary' : 'Secondary'}</Text>
                                                </Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}>
                                                    <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>{card?.bank_name}</Text>
                                                </Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}>
                                                    <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>{card?.card_number.slice(0, -4).replace(/\d/g, '*') + card?.card_number.slice(-4)}</Text>
                                                </Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}>
                                                    <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                                    <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>{card?.card_name}</Text>
                                                </Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                                <Td border={'none'} pl={'0px'} pr={'0px'}>
                                                    <Menu>
                                                        <MenuButton>
                                                            <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                        </MenuButton>
                                                        <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                            <MenuItem
                                                                transition={'all 0.3s ease-in-out'}
                                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                                _focus={{ bgColor: '#2bc255', color: '#fff' }}
                                                                onClick={() => editCard(card?.id)}
                                                            >
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem
                                                                transition={'all 0.3s ease-in-out'}
                                                                _hover={{ bgColor: '#2bc255', color: '#fff' }}
                                                                onClick={() => openDeleteModal(card?.id)}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                    {/* <Tr>
                                        <Td><Img src={card2} /></Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                        </Td>
                                        <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td><Img src={card3} /></Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                        </Td>
                                        <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td><Img src={card4} /></Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Type</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Secondary</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Bank</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>ABC Bank</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Card Number</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>**** **** **** 1234</Text>
                                        </Td>
                                        <Td>
                                            <Text color={'#000000'} mb={'5px'}>Name on Card</Text>
                                            <Text fontSize={'16px'} color={'#000000'} fontWeight={'600'}>Garcia Jr.</Text>
                                        </Td>
                                        <Td><Link style={{ color: '#1EAAE7', fontWeight: '600', fontSize: '14px' }}>See Balance</Link></Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <FaEllipsisV fontSize={'24px'} color={'#000000'} />
                                                </MenuButton>
                                                <MenuList m={'0px'} p={'0px'} borderRadius={'5px'} overflow={'hidden'}>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }} _focus={{ bgColor: '#2bc255', color: '#fff' }}>Edit</MenuItem>
                                                    <MenuItem transition={'all 0.3s ease-in-out'} _hover={{ bgColor: '#2bc255', color: '#fff' }}>Delete</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr> */}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        :
                        <Box textAlign={'center'} p={'20px'}>
                            <Text
                                color={'#ff0000'}
                                fontSize={'18px'}
                                fontWeight={'600'}
                                m={0}
                            >No Cards Found.</Text>
                        </Box>
                }
            </Box>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <FaTrash />
                        <Text ml={'10px'}>Delete Card?</Text>
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Are you sure you want to delete this card?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            backgroundColor={'#2BC155'}
                            borderRadius={'50px'}
                            padding={'10px 10px 10px 10px'}
                            color={'#fff'}
                            border={'1px solid #2BC155'}
                            display={'inline-flex'}
                            alignItems={'center'}
                            className="group"
                            transition={'all 0.3s ease-in-out'}
                            _hover={{
                                backgroundColor: '#fff',
                                color: '#2BC155',
                                border: '1px solid #2BC155'
                            }}
                            onClick={onClose}
                        >
                            <Box
                                bgColor={'#fff'}
                                color={'#2BC155'}
                                borderRadius={'50%'}
                                w={'25px'}
                                h={'25px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                transition={'all 0.3s ease-in-out'}
                                mr={2}
                                _groupHover={{
                                    bgColor: '#2BC155',
                                    color: '#fff',
                                }}
                            >
                                <FaTimes />
                            </Box>
                            No
                        </Button>
                        <Button
                            ml={3}
                            isLoading={isCardDeletingLoading}
                            backgroundColor={'#0A2B1B'}
                            borderRadius={'50px'}
                            padding={'10px 10px 10px 10px'}
                            color={'#fff'}
                            border={'1px solid #0A2B1B'}
                            display={'inline-flex'}
                            alignItems={'center'}
                            className="group"
                            transition={'all 0.3s ease-in-out'}
                            _hover={{
                                backgroundColor: '#fff',
                                color: '#0A2B1B',
                                border: '1px solid #0A2B1B'
                            }}
                            onClick={onDeleteCard}
                        >
                            <Box
                                bgColor={'#fff'}
                                color={'#0A2B1B'}
                                borderRadius={'50%'}
                                w={'25px'}
                                h={'25px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                transition={'all 0.3s ease-in-out'}
                                mr={2}
                                _groupHover={{
                                    bgColor: '#0A2B1B',
                                    color: '#fff',
                                }}
                            >
                                <FaCheck />
                            </Box>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
}

export default CardsCenter;
